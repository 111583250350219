import './index.scss';


const Footer = () => {
    return (
        <a className="footer-container" href={"https://portfolio.luctatu.fr/"}> Actibus immensis urbs fulget Massiliensis </a>
    );
}

export default Footer;
