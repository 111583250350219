import './index.scss';

import React from "react";
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCirclePlus, faClose } from "@fortawesome/free-solid-svg-icons";
import Me from "../../assets/me.png";

const Header = ({ isLoggedIn, setShowLogin, setIsLoggedIn, setShowAddLink, showAddLink }) => {
    return (
        <>
            <div className="new-item-btn-container">
                {isLoggedIn &&
                    <button onClick={() => setShowAddLink(!showAddLink)} className="add-button">
                        <FontAwesomeIcon icon={faCirclePlus}/>
                    </button>
                }
            </div>
            <h1>My Work Space</h1>
            <div className="login-btn-container">
                {isLoggedIn ?
                    <div onClick={() => setIsLoggedIn(false)} className="logout-button">
                        <FontAwesomeIcon icon={faClose}/>
                    </div>
                    :
                    <div onClick={() => setShowLogin(true)} className="login-button">
                        <img src={Me} alt="login"/>
                    </div>
                }
            </div>
        </>
    );
}

Header.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    setShowLogin: PropTypes.func.isRequired,
    setIsLoggedIn: PropTypes.func.isRequired,
    setShowAddLink: PropTypes.func.isRequired,
    showAddLink: PropTypes.bool.isRequired,
};

export default Header;
