import './index.scss';

import React from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MIN_CATEGORIES, padCategories, sortCategories, sortLinks } from '../../Tools';
import { faEllipsis, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

const Body = ({ isLoggedIn, categories, links }) => {
    const categoriesToDisplay = sortCategories(padCategories(categories, MIN_CATEGORIES));

    const linksByCategory = categoriesToDisplay.reduce((acc, categoryObj) => {
        const { category } = categoryObj;
        acc[category] = sortLinks(links.filter(link => link.category === category));
        return acc;
    }, {});

    const editLink = (link) => {
        console.log("Edit Link:", link);
    };

    const editCategory = () => {
        console.log("Edit Category TODO");
    };

    return (
        <>
            {categoriesToDisplay.map((categoryObj) => (
                <div
                    key={categoryObj.idx}
                    className={`category-container ${categoryObj.category === "" ? "empty-category" : ""}`}
                >
                    {categoryObj.category && (
                        <>
                            <div className="category-header">
                                <h2 className="category-title">{categoryObj.category}</h2>
                                {isLoggedIn && (
                                    <FontAwesomeIcon icon={faEllipsis}
                                                     className="category-icon"
                                                     onClick={editCategory} />
                                )}
                            </div>
                            {linksByCategory[categoryObj.category].map((link) => (
                                <div className="link-container" key={link.name}>
                                    <a className="link"
                                       rel="noopener noreferrer"
                                       href={link.url}
                                       target="_blank"
                                       onClick={(e) => {
                                           if (e.defaultPrevented) return 1;
                                       }}
                                    >
                                        <img src={link.image} className="link-image" alt="" />
                                        <span className="link-text">{link.name}</span>
                                    </a>
                                    {isLoggedIn && (
                                        <FontAwesomeIcon icon={faEllipsisVertical}
                                                         className="link-icon"
                                                         onClick={(e) => {
                                                             e.preventDefault();
                                                             editLink(link);
                                                         }} />
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </div>
            ))}
        </>
    );
};

Body.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    links: PropTypes.array.isRequired,
};

export default Body;
