import './index.scss';

import React, { useRef } from "react";
import PropTypes from 'prop-types';

import { db, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import default_img from '../../assets/default_img.png';

import {
    SUPPORTED_IMAGE_FORMATS,
    calculateNextIdx,
    uploadDefaultImage,
    getFormattedValue
} from '../../Tools';

const AddLink = ({ categories, setCategories, links, setLinks, setShowAddLink }) => {
    const form = useRef();

    const handleAddLink = async (e) => {
        e.preventDefault();

        const category = getFormattedValue(form.current[0]?.value);
        const name = getFormattedValue(form.current[1]?.value);
        const url = form.current[2]?.value || '';
        const image = form.current[3]?.files[0] || null;

        if (category && name && url) {
            if (image && !SUPPORTED_IMAGE_FORMATS.includes(image.type)) {
                alert('Unsupported image format. Please upload a JPEG or PNG.');
                return;
            }

            try {
                const linkExists = links.some(link => link.name === name);

                if (linkExists) {
                    alert('A link with this name already exists.');
                    return;
                }

                let newCategory = null;
                if (!categories.some(cat => cat.category === category)) {
                    const nextIdx = calculateNextIdx(categories);
                    newCategory = {
                        category,
                        idx: nextIdx
                    };
                    await addDoc(collection(db, "categories"), newCategory);

                    const querySnapshot = await getDocs(collection(db, "categories"));
                    const sortedCategories = querySnapshot.docs
                        .map((doc) => doc.data())
                        .filter(cat => cat.category !== "")
                        .sort((a, b) => a.idx - b.idx);

                    setCategories(sortedCategories);
                }

                let downloadUrl = await uploadDefaultImage(default_img, storage);

                if (image) {
                    const storageRef = ref(storage, `image_link/${image.name}`);
                    const snapshot = await uploadBytes(storageRef, image);
                    downloadUrl = await getDownloadURL(snapshot.ref);
                }

                const newLink = {
                    category,
                    name,
                    url,
                    image: downloadUrl
                };

                await addDoc(collection(db, 'links'), newLink);
                setLinks(prevLinks => [...prevLinks, newLink]);
                setShowAddLink(false);

            } catch (error) {
                alert("Failed to add link:\n" + error);
            }
        } else {
            alert('All fields are required.');
        }
    };

    return (
        <div className="addLink-container">
            <div className="addLink-form">
                <button className="close-button" onClick={() => setShowAddLink(false)}>
                    ×
                </button>
                <h2>Add New Link</h2>
                <form ref={form} onSubmit={handleAddLink}>
                    <p><input type="text" name="category" placeholder="Category" required /></p>
                    <p><input type="text" name="name" placeholder="Link Name" required /></p>
                    <p><input type="url" name="url" placeholder="URL" required /></p>
                    <p><input type="file" name="image" placeholder="Image" /></p>
                    <button type="submit">Add Link</button>
                </form>
            </div>
        </div>
    );
};

AddLink.propTypes = {
    categories: PropTypes.array.isRequired,
    setCategories: PropTypes.func.isRequired,
    links: PropTypes.array.isRequired,
    setLinks: PropTypes.func.isRequired,
    setShowAddLink: PropTypes.func.isRequired,
};

export default AddLink;
