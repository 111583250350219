import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export const MIN_CATEGORIES = 5;
export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png'];

export const calculateNextIdx = (categories) => {
    const maxIdx = categories
        .filter(cat => cat.category !== "")
        .reduce((max, cat) => Math.max(max, cat.idx), 0);

    return maxIdx + 1;
};

export const getFormattedValue = (value) => {
    return value
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const padCategories = (categories, minCategories) => {
    const categoriesToDisplay = [...categories];
    const totalCategories = categoriesToDisplay.length;
    const remainder = totalCategories % minCategories;

    if (remainder !== 0) {
        const emptyCategoriesCount = minCategories - remainder;
        for (let i = 0; i < emptyCategoriesCount; i++) {
            categoriesToDisplay.push({ category: "", idx: 10000 + i });
        }
    }

    return categoriesToDisplay;
};

export const sortCategories = (categories) => {
    return categories.sort((a, b) => a.idx - b.idx);
};

export const sortLinks = (links) => {
    return links.sort((a, b) => a.name.localeCompare(b.name));
};

export const uploadDefaultImage = async (default_img, storage) => {
    const response = await fetch(default_img);
    const blob = await response.blob();
    const defaultImageName = 'default_image.png';
    const storageRef = ref(storage, `image_link/${defaultImageName}`);
    await uploadBytes(storageRef, blob);
    return await getDownloadURL(storageRef);
};

