import './index.scss';

import React, { useState } from "react";
import PropTypes from 'prop-types';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

const Login = ({ setShowLogin, setIsLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin =  async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setIsLoggedIn(true);
            setShowLogin(false);
        } catch (error) {
            alert("Error logging: \n" + error.message);
        }

        setEmail('');
        setPassword('');
    }

    return (
        <div className="login-container">
            <div className="login-form">
                <button className="close-button" onClick={() => setShowLogin(false)}>
                    ×
                </button>
                <h2>Login</h2>
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Log In</button>
                </form>
            </div>
        </div>
    );
}

Login.propTypes = {
    setShowLogin: PropTypes.func.isRequired,
    setIsLoggedIn: PropTypes.func.isRequired,
}

export default Login;
