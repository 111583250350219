import './App.scss';

import React, { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { db } from './firebase';

import Header from './containers/Header';
import Body from './containers/Body';
import Footer from './containers/Footer';

import Login from './components/Login';
import AddLink from "./components/AddLink";

function App() {
    const [showLogin, setShowLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showAddLink, setShowAddLink] = useState(false);

    const [categories, setCategories] = useState([]);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        getCategories().then(r => {});
        getLinks().then(r => {});
    }, []);

    const getCategories = async () => {
        const querySnapshot = await getDocs(collection(db, 'categories'));

        const sortedCategories = querySnapshot.docs
            .map((doc) => doc.data())
            .sort((a, b) => a.idx - b.idx);

        setCategories(sortedCategories);
    };

    const getLinks = async () => {
        const querySnapshot = await getDocs(collection(db, 'links'));
        setLinks(querySnapshot.docs.map((doc) => doc.data()));
    }

    return (
        <div className="app-container">
            <div className="app-header">
                <Header isLoggedIn={isLoggedIn} setShowLogin={setShowLogin} setIsLoggedIn={setIsLoggedIn}
                        setShowAddLink={setShowAddLink} showAddLink={showAddLink}/>
            </div>
            <div className="app-body">
                {showLogin && (
                    <Login setShowLogin={setShowLogin} setIsLoggedIn={setIsLoggedIn} />
                )}
                {showAddLink && (
                    <AddLink categories={categories} setCategories={setCategories}
                             links={links} setLinks={setLinks} setShowAddLink={setShowAddLink}/>
                )}
                <Body isLoggedIn={isLoggedIn} categories={categories} links={links} />
            </div>
            <div className="app-footer">
                <Footer />
            </div>
        </div>
    );
}

export default App;
